import { ButtonSize, ButtonVariants } from "@components/ui/button/index"
import { cn } from "@lib/cn"

export const getBasicButtonClass = (isLoading?: boolean) =>
  cn(
    "relative flex items-center justify-center transition-all duration-150 overflow-hidden z-[1]",
    "focus-visible:outline-none focus-visible:shadow-[0_0_0_3px] focus-visible:!shadow-shade-300/50 disabled:focus-visible:outline-auto disabled:focus-within:!shadow-none",
    "active:scale-[0.98] active:before:opacity-0",
    isLoading && "child-span:opacity-0",
  )

export const getButtonClass = (size?: ButtonSize) =>
  cn(
    "font-semibold px-6",
    size === "regular" && "min-h-12 rounded-3xl text-base",
    size === "small" && "min-h-10 rounded-3xl text-sm",
  )

export const getVariantClass = (
  variant: ButtonVariants,
  isLoading?: boolean,
) => {
  switch (variant) {
    case "primary":
      return getVariantPrimaryClass(isLoading)
    case "secondary":
      return getVariantSecondaryClass(isLoading)
    case "danger":
      return getVariantDangerClass(isLoading)
    case "white":
      return getVariantWhiteClass(isLoading)
    default:
      return ""
  }
}

export const getVariantPrimaryClass = (isLoading?: boolean) =>
  cn(
    "bg-default enabled:bg-default", // disabled:bg-none to clear background image does not work, not sure why
    "before:absolute before:z-[-1] before:bg-hover before:left-0 before:top-0 before:h-full before:w-full before:transition-opacity before:duration-150 before:opacity-0",
    "disabled:bg-none disabled:bg-shade-500 disabled:pointer-events-none",
    "hover:before:opacity-100 enabled:hover:before:opacity-100",
    isLoading && "opacity-100",
  )

export const getVariantSecondaryClass = (isLoading?: boolean) =>
  cn(
    "bg-transparent border border-shade-600",
    "hover:border-shade-400 enabled:hover:border-shade-400",
    "active:border-shade-400",
    "disabled:opacity-50 disabled:pointer-events-none",
    isLoading && "border-shade-600/50 cursor-not-allowed opacity-100",
  )

export const getVariantDangerClass = (isLoading?: boolean) =>
  cn(
    "bg-danger",
    "hover:bg-[#EF1C12] enabled:hover:bg-[#EF1C12]",
    "disabled:opacity-50 disabled:pointer-events-none",
    isLoading && "bg-danger/50 cursor-not-allowed opacity-100",
  )

export const getVariantWhiteClass = (isLoading?: boolean) =>
  cn(
    "bg-white text-purple",
    "hover:bg-[#E5E5E5] enabled:hover:bg-[#E5E5E5]",
    "disabled:opacity-50 disabled:pointer-events-none",
    isLoading && "bg-white/50 cursor-not-allowed opacity-100",
  )
