import React, { FC } from "react"

type ButtonLoadingSvgProps = {
  size?: number
}

export const ButtonLoadingSvg: FC<ButtonLoadingSvgProps> = ({ size = 8 }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 8 8"
      className="absolute left-0 right-0 top-1/2 mx-auto my-0 -translate-y-1/2 transform"
    >
      <circle cx="4" cy="4" r="4" fillOpacity="0.3" fill="#fff">
        <animate
          attributeName="fill-opacity"
          begin="0s"
          calcMode="linear"
          dur=".8s"
          from="0.1"
          repeatCount="indefinite"
          to="0.1"
          values=".1;1;.1"
        ></animate>
      </circle>
    </svg>
  )
}
