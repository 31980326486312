import { cn } from "@lib/cn"
import * as Dialog from "@radix-ui/react-dialog"
import React, { FC, ReactNode } from "react"
import { IconButton } from "../button/IconButton"
import { Heading } from "../typograhpy/Heading"

export type ModalOrSidebarProps = {
  title: string
  trigger?: React.ReactElement
  onOpenChange?: (open: boolean) => void
  open?: boolean
  disableClose?: boolean
  footer?: React.ReactElement
  children?: ReactNode
}

export const ModalOrSidebar: FC<ModalOrSidebarProps> = ({
  trigger,
  title,
  children,
  open,
  onOpenChange,
  disableClose = false,
  footer,
}) => {
  return (
    <Dialog.Root onOpenChange={onOpenChange} open={open}>
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Portal>
        <Dialog.Overlay
          className={cn(
            "fixed left-0 top-0 z-100 h-full w-full animate-fade-in border-none bg-background/70 outline-none",
          )}
        />
        <Dialog.Content
          className={cn(
            "!pointer-events-none fixed left-0 top-0 z-101 flex h-full w-full items-center justify-end border-none outline-none lg:justify-center",
            // use selector instead of `open` state because it is optional, so cannot rely on it
            "data-[state=closed]:animate-modal-to-bottom data-[state=open]:animate-modal-from-bottom",
          )}
        >
          <div className="pointer-events-auto right-0 top-0 h-full w-full bg-sidebar md:max-w-[27.5rem] lg:left-0 lg:mx-auto lg:my-0 lg:h-auto lg:min-h-[10rem]">
            <div className="relative grid h-full w-full grid-cols-[1fr] grid-rows-[auto_1fr]">
              <div className="text-center">
                <div className="mb-6 flex items-center justify-between border-b border-b-border px-5 py-4 text-left lg:mb-8 lg:border-b-0 lg:px-8 lg:pb-0 lg:pt-6">
                  <Heading variant="h5" as="h5" className="mb-0">
                    {title}
                  </Heading>
                  {!disableClose && (
                    <Dialog.Close asChild>
                      <IconButton
                        as="button"
                        size="small"
                        variant="secondary"
                        icon="close"
                        label={`Close ${title}`}
                      />
                    </Dialog.Close>
                  )}
                </div>
              </div>
              <div
                className={cn(
                  "scrollbar-custom min-h-[calc(100%-4.5rem)] overflow-y-auto p-5 pt-0 lg:max-h-[80vh] lg:p-8 lg:pt-0",
                )}
              >
                {children}
              </div>
              {footer && (
                <div className="border-t border-t-border p-5">{footer}</div>
              )}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
