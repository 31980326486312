import { Button } from "@components/ui/button"
import { Text } from "@components/ui/typograhpy/Text"
import { cn } from "@lib/cn"
import { motion } from "framer-motion"
import Link from "next/link"
import { FC } from "react"

type CookieNoticeProps = {
  onClickAgree: () => void
  onClickMoreOptions: () => void
}

export const CookieNotice: FC<CookieNoticeProps> = ({
  onClickAgree,
  onClickMoreOptions,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20, visibility: "hidden" }}
      animate={{ opacity: 1, y: 0, visibility: "visible" }}
      transition={{ ease: "easeInOut", duration: 0.3, delay: 1 }}
      className={cn(
        "fixed bottom-5 left-0 right-0 mx-auto my-0 w-full max-w-[calc(100%-2rem)]",
        "z-[200] rounded-lg bg-opacity p-4 shadow-[0px_54px_67px_rgba(0,0,0,0.11)]",
        "sm:bottom-10 sm:max-w-[46rem] sm:p-8 md:bottom-12",
      )}
    >
      <div
        className={cn(
          "grid grid-flow-row gap-y-12",
          "gap-x-4 sm:grid-flow-col sm:grid-cols-[1fr_auto]",
        )}
      >
        <Text variant="small">
          We use cookies to analyze site performance and deliver personalized
          content. By clicking “Agree”, you consent to our{" "}
          <Link
            href="/cookie-policy"
            className="bg-default bg-clip-text [-webkit-text-fill-color:transparent]"
          >
            Cookie Policy
          </Link>
          . You may change your settings at any time.
        </Text>
        <div
          className={cn(
            "grid grid-cols-2 items-center gap-x-4",
            "sm:max-w-[246px] sm:grid-cols-[1fr_auto] sm:justify-end",
          )}
        >
          <Button
            variant="primary"
            onClick={onClickAgree}
            className="col-[2] row-[1] sm:col-auto"
          >
            Agree
          </Button>
          <Button variant="secondary" onClick={onClickMoreOptions}>
            More options
          </Button>
        </div>
      </div>
    </motion.div>
  )
}
