import { QueryCache, QueryClient } from "@tanstack/react-query"
import { ClientError } from "graphql-request"
import { signOut } from "next-auth/react"

export function extractErrorCode(error) {
  if (error instanceof ClientError) {
    return error.response?.errors?.[0].message
  }
  return error.message
}

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (
        error instanceof ClientError &&
        error.response?.errors?.[0].message.includes("unauthorized")
      ) {
        signOut()
      }
    },
  }),
})
