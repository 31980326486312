import { cn } from "@lib/cn"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"
import React from "react"
import { ButtonIcon, getIcon } from "./helpers/get-icon"
import { ButtonUiProps, LinkType } from "./index"
import { ButtonLoadingSvg } from "./Loading"
import { getBasicButtonClass, getVariantClass } from "./styles"

type IconButtonProps = ButtonUiProps &
  LinkType & {
    icon: ButtonIcon
    label: string
    size?: "extra-small" | "small" | "regular"
  }

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      variant = "primary",
      status = "idle",
      icon,
      className,
      as = "button",
      size = "regular",
      label,
      ...rest
    },
    ref,
  ) => {
    const Component = as === "link" ? "a" : as === "button" ? "button" : "span"
    return (
      // @ts-ignore
      <Component
        ref={ref}
        className={cn(
          getBasicButtonClass(status === "loading"),
          "min-h-12 min-w-12 rounded-3xl",
          size === "small" && "min-h-10 min-w-10",
          size === "extra-small" && "min-h-8 min-w-8",
          getVariantClass(variant, status === "loading"),
          className,
        )}
        data-variant={variant}
        data-size={size}
        data-status={status}
        disabled={status === "disabled" || status === "loading"}
        {...rest}
      >
        <span className="flex items-center">
          {getIcon(icon, variant === "secondary" ? 16 : 24)}
        </span>
        <VisuallyHidden.Root>{label}</VisuallyHidden.Root>
        {status === "loading" && <ButtonLoadingSvg />}
      </Component>
    )
  },
)

IconButton.displayName = "IconButton"
