import { Text } from "@components/ui/typograhpy/Text"
import { cn } from "@lib/cn"
import * as Portal from "@radix-ui/react-portal"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { FC } from "react"

export const ExitPreview: FC = () => {
  const { isPreview } = useRouter()
  return (
    <>
      {isPreview && (
        <Portal.Root>
          <Link
            href="/api/exit-preview"
            className={cn(
              "fixed right-0 top-1/2 z-[99] block translate-y-[100%] -rotate-90 rounded-sm bg-shade-300",
              "px-4 py-2 transition-all duration-200 hover:bg-white",
            )}
          >
            <Text variant="small" className="text-shade-800">
              Exit preview
            </Text>
          </Link>
        </Portal.Root>
      )}
    </>
  )
}
