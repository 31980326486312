import { cn } from "@lib/cn"
import { FC, HTMLAttributes } from "react"

type TextVariant = "special" | "large" | "regular" | "small" | "micro"
type TextProps = HTMLAttributes<HTMLElement> & {
  variant: TextVariant
  as?: "p" | "div" | "span" | "li"
}

// use direct arbitrary value for font-size to prevent line-height apply
const variantClass: Record<TextVariant, string> = {
  special: "font-semibold text-xxl lg:text-xl",
  large: "text-lg lg:text-base",
  regular: "text-base lg:text-sm",
  small: "text-sm",
  micro: "text-sm lg:text-xs",
}
export const Text: FC<TextProps> = ({
  as = "div",
  variant,
  className,
  ...rest
}) => {
  const Comp = as
  return (
    <Comp
      // Keep data-* attributes for backward compatibility with  other usages
      // TODO: remove data-* attributes when everything is migrated to tailwind
      data-variant={variant}
      className={cn(variantClass[variant], "leading-normal", className)}
      {...rest}
    />
  )
}
