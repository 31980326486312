import { Cookie } from "@components/cookie"
import { Debug } from "@components/Debug"
import { ExitPreview } from "@components/ExitPreview"
import { Head } from "@components/head"
import { Tracking } from "@components/Tracking"
import { queryClient } from "@lib/query-client"
import { TooltipProvider } from "@radix-ui/react-tooltip"
import {
  QueryClient,
  QueryClientProvider,
  QueryClientProvider as QueryClientProvider2,
} from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { domMax, LazyMotion } from "framer-motion"
import { SessionProvider } from "next-auth/react"
import "../styles/font-face.css"
import "../styles/global-styles.css"
import "../styles/reset.css"

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head />
      <Debug />
      <Tracking />
      <ExitPreview />
      <TooltipProvider>
        <SessionProvider session={pageProps.session}>
          <QueryClientProvider2 client={new QueryClient()}>
            <QueryClientProvider client={queryClient}>
              <LazyMotion features={domMax}>
                <Component {...pageProps} />
              </LazyMotion>
              {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
            </QueryClientProvider>
          </QueryClientProvider2>
        </SessionProvider>
      </TooltipProvider>
      <Cookie />
    </>
  )
}

export default MyApp
