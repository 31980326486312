import { cn } from "@lib/cn"
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  HTMLAttributes,
} from "react"
import { getIcon } from "./helpers/get-icon"
import { ButtonLoadingSvg } from "./Loading"
import { getBasicButtonClass, getButtonClass, getVariantClass } from "./styles"

export type ButtonVariants = "primary" | "secondary" | "danger" | "white"
export type ButtonUiProps = {
  variant?: ButtonVariants
  status?: "disabled" | "idle" | "loading"
  as?: "button" | "link" | "span"
}

type LinkVariant = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "color"> & {
  as?: "link"
  ref?: any
  href?: string
}
type ButtonVariant = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color"> & {
  as?: "button"
}
type SpanVariant = Omit<HTMLAttributes<HTMLSpanElement>, "color"> & {
  as?: "span"
}

export type ButtonIcon =
  | "folder"
  | "download"
  | "chevron-left"
  | "chevron-right"
  | "close"
  | "edit"
  | "fullscreen"
  | "apple"
  | "google"
  | "icon-unread"
  | "check"
  | "contact"
  | "select"
  | "upload"
  | "external"
  | "plus"
  | "delete"
  | "refresh"
  | "copy"
  | "enhance"
  | "arrow-left"
  | "dots"
  | "settings"
  | "deselect"
  | "workflows"
  | "code"
  | "rocket"
  | "duplicate"

export type LinkType = ButtonVariant | LinkVariant | SpanVariant

export type ButtonSize = "small" | "regular"

type ButtonProps = ButtonUiProps &
  LinkType & {
    size?: ButtonSize
    leftIcon?: ButtonIcon
    rightIcon?: ButtonIcon
    width?: "auto" | "fill"
  }

export const Button = forwardRef<any, ButtonProps>((props, ref) => {
  const {
    variant = "primary",
    width = "auto",
    status = "idle",
    size = "regular",
    as = "button",
    leftIcon,
    rightIcon,
    children,
    className,
    ...rest
  } = props
  const Component = as === "link" ? "a" : as === "button" ? "button" : "span"
  return (
    // @ts-ignore
    <Component
      ref={ref}
      className={cn(
        getBasicButtonClass(status === "loading"),
        getButtonClass(size),
        width === "fill" ? "w-full" : "",
        getVariantClass(variant, status === "loading"),
        "text-inherit", //replace for previous color inherit
        className,
      )}
      // Keep data-* attributes for backward compatibility with  other usages
      // TODO: remove data-* attributes when everything is migrated to tailwind
      data-variant={variant}
      data-status={status}
      data-fill-width={width === "fill" ? "" : undefined}
      data-size={size}
      disabled={status === "disabled" || status === "loading"}
      {...rest}
    >
      <span className="flex items-center">
        {leftIcon && (
          <span className="mr-2 flex">
            {getIcon(leftIcon, size === "regular" ? 24 : 20)}
          </span>
        )}
        <span data-button-text className="flex">
          {children}
        </span>
        {rightIcon && (
          <span className="ml-2 flex">
            {getIcon(rightIcon, size === "regular" ? 24 : 20)}
          </span>
        )}
      </span>
      {status === "loading" && <ButtonLoadingSvg />}
    </Component>
  )
})
Button.displayName = "Button"
