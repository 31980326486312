import { Button } from "@components/ui/button"
import { ModalOrSidebar } from "@components/ui/modal-sidebar"
import { Text } from "@components/ui/typograhpy/Text"
import Link from "next/link"
import React, { FC } from "react"

type CookieModalProps = {
  onClickAcceptAll: () => void
  onClickAcceptFuntional: () => void
}

export const CookieModal: FC<CookieModalProps> = ({
  onClickAcceptAll,
  onClickAcceptFuntional,
}) => {
  return (
    <ModalOrSidebar open title={"Cookies"} disableClose>
      <div>
        <Text variant="regular" className="mb-4">
          We use cookies to remember your login details, provide a secure log-in
          experience, collect statistics to optimize site functionality, and
          deliver content and advertising tailored to your interests. You can
          view detailed descriptions of the types of cookies we use in our{" "}
          <Link
            href="/cookie-policy"
            className="bg-default bg-clip-text [-webkit-text-fill-color:transparent]"
          >
            Cookie Policy
          </Link>
          . <br />
          <br />
          Click Agree to accept all cookies or Decline to allow only necessary
          cookies. You may change your preferences at any time by clicking the
          ”Cookies“ link in the footer of the page.
        </Text>
        <div className="flex flex-row-reverse items-end gap-2 sm:flex-row">
          <Button variant="primary" onClick={onClickAcceptAll}>
            Agree
          </Button>
          <Button variant="secondary" onClick={onClickAcceptFuntional}>
            Decline
          </Button>
        </div>
      </div>
    </ModalOrSidebar>
  )
}
